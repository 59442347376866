import React, { useState } from "react"
// import { Link } from "gatsby"
import Img from "gatsby-image"

import "./SigninForm.css"

export default ({ logo }) => {
  const [submitText, setSubmitText] = useState({ success: {}, error: {} })

  const InputField = ({ fieldName, fieldTitle }) => (
    <div className="input-field">
      <label htmlFor={fieldName}>{fieldTitle}</label>
      <input type="text" id={fieldName} name={fieldName} />
    </div>
  )

  const RadioButtons = ({ fieldName, fieldValues }) => (
    <div className="signin-radio">
      <div className="radio-buttons">
        {fieldValues.map(value => (
          <div className="radio-button" key={value}>
            <input type="radio" id={value} name={fieldName} value={value} />
            <label htmlFor={value}>{value}</label>
          </div>
        ))}
      </div>
    </div>
  )

  const validate = e => {
    const { form_tools_form_id, email, phone, oegb } = e.target
    const error = {}

    const regMail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (!form_tools_form_id.value) {
      error.form =
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
    }

    if (!email.value && !phone.value) {
      error.requiredContact = "Email oder Telefon wurde nicht ausgefüllt."
    }

    if (email.value && !regMail.test(email.value)) {
      error.email = "Email ungültig."
    }

    if (!oegb.checked) {
      error.oegb = "Die ÖGB müssen zugestimmt werden."
    }

    setSubmitText({ success: {}, error })

    if (!Object.keys(error).length) {
      return true
    }

    return false
  }

  const onSubmit = e => {
    e.preventDefault()

    if (validate(e)) {
      const fd = new FormData(e.target)

      fetch(e.target.action, {
        method: "POST",
        redirect: "manual",
        body: fd,
      }).then(response => {
        if (response.type === "opaqueredirect") {
          // success
          setSubmitText({
            success: {
              text: "Ihre Anfrage wurde zugeschickt! Wir melden uns in Kürze.",
            },
            error: {},
          })
        } else {
          // failed
          setSubmitText({
            success: {},
            error: {
              failed:
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            },
          })
        }
      })
    }
  }

  return (
    <div id="signin">
      <form
        action="https://form.younion.qnipp.com/process.php"
        method="post"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="form_tools_form_id" value="6" />
        <div className="signin-info">
          {/* 1st row */}
          <div className="row">
            <div className="col-2">
              <InputField fieldName="aktitle" fieldTitle="Akad. Titel" />
            </div>
            <div className="col-2">
              <InputField fieldName="brithday" fieldTitle="Geburtsdatum" />
            </div>
            <div className="col-2">
              <InputField fieldName="personalnum" fieldTitle="Personalnummer" />
            </div>
            <div className="col-2">
              <InputField fieldName="membernum" fieldTitle="Mitgliedsnummer" />
            </div>
          </div>

          {/* 2nd row */}
          <div className="row">
            <div className="col-4">
              <InputField fieldName="surname" fieldTitle="Familienname" />
            </div>
            <div className="col-4">
              <InputField
                fieldName="currentoccupation"
                fieldTitle="Derzeitige Tätigkeit (Beruf/Dienstverwendung)"
              />
            </div>
          </div>

          {/* 3rd row */}
          <div className="row">
            <div className="col-2">
              <InputField fieldName="forename" fieldTitle="Vorname" />
            </div>
            <div className="col-2">
              <div className="signin-gender">
                <fieldset>
                  <span>Geschlecht</span>
                  <div>
                    <label htmlFor={"männlich"}>
                      <input
                        type="radio"
                        id="männlich"
                        name="gender"
                        value="männlich"
                      />
                      männlich
                    </label>

                    <label htmlFor="weiblich">
                      <input
                        type="radio"
                        id="weiblich"
                        name="gender"
                        value="weiblich"
                      />
                      weiblich
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col-4">
              <InputField fieldName="employedat" fieldTitle="Beschäftigt bei" />
            </div>
          </div>

          {/* 4th row */}
          <div className="row">
            <div className="col-4">
              <InputField fieldName="street" fieldTitle="Straße, Hausnummer" />
            </div>
            <div className="col-4">
              <InputField
                fieldName="streetemployed"
                fieldTitle="Straße, Hausnummer des Arbeitgebers"
              />
            </div>
          </div>

          {/* 5th row */}
          <div className="row">
            <div className="col-1">
              <InputField fieldName="plz" fieldTitle="PLZ" />
            </div>
            <div className="col-3">
              <InputField fieldName="place" fieldTitle="Wohnort" />
            </div>
            <div className="col-1">
              <InputField
                fieldName="plzemployed"
                fieldTitle="PLZ des Arbeitgebers"
              />
            </div>
            <div className="col-3">
              <InputField
                fieldName="placeemployed"
                fieldTitle="Ort des Arbeitgebers"
              />
            </div>
          </div>

          {/* 6th row */}
          <div className="row">
            <div className="col-1">
              <InputField
                fieldName="plnationalityz"
                fieldTitle="Staatsangehörigkeit"
              />
            </div>
            <div className="col-3">
              <InputField fieldName="phone" fieldTitle="Telefonnummer" />
            </div>
            <div className="col-4">
              <InputField
                fieldName="startworking"
                fieldTitle="Datum Diensteintritt, Arbeitsbeginn"
              />
            </div>
          </div>

          {/* 7th row */}
          <div className="row">
            <div className="col-4">
              <InputField fieldName="email" fieldTitle="Email-Adresse" />
            </div>
            <div className="col-4">
              <InputField
                fieldName="premembership"
                fieldTitle="Vormitgliedschaft (Gewerkschaft und Zeitraum)"
              />
            </div>
          </div>

          {/* 8th row */}
          <div className="row">
            <RadioButtons
              fieldName="typeemployment"
              fieldValues={[
                "Beamter/in",
                "Vertragsbedienstete/r",
                "Saisonarbeiter/in",
                "Angstellte/r",
                "Arbeiter/in",
                "Pensionist/in",
                "Lehrling",
                "Schüler/in, Student/in",
                "Praktikant/in",
                "freiberuflich",
                "geringfügig",
                "Teilzeit beschäftigt",
              ]}
            />
          </div>
        </div>
        {/* OEGB */}
        <div className="signin-oegb">
          <label>
            <input type="checkbox" name="oegb" value="oegb" />
            <span>
              Ich willige ein, dass ÖGB, ÖGB Verlag und/oder VÖGB mich
              telefonisch bzw. per elektronischer Post (§107 TKG) kontaktieren
              dürfen, um über Serviceleistungen, etwa Aktionen für Tickets,
              Bücher, Veranstaltungen udgl., zu informieren und sonstige
              Informationen zu übermitteln. Die Einwilligung kann jederzeit
              widerrufen werden.
            </span>
          </label>
        </div>
        <p>
          Ich bestätige, die unten angeführte Datenschutzerklärung (auch
          abrufbar unter{" "}
          <a href="http://www.oegb.at/datenschutz">www.oegb.at/datenschutz</a>)
          zur Kenntnis genommen zu haben.
        </p>
        <div className="signin-company-deduction">
          <div>
            <h1>Betriebsabzug:</h1>
            Ich erkläre, dass
            <ul>
              <li>
                ich dem Betriebsabzug zustimme, also mein Gewerkschaftsbeitrag
                durch meinen Arbeitgeber/Dienstgeber von meinem Gehalt bzw.
                Lohn, meiner Lehrlingsentschädigung abgezogen werden kann; bzw.
                durch die pensionsauszahlende Stelle von meiner Pension
                einbehalten und überwiesen wird; und
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                ich erteile deshalb meine Einwilligung, dass meine im
                Zusammenhang mit der Beitragseinhebung erforderlichen
                personenbezogenen Daten, nämlich oben angegebene Daten und in
                jeweils aktueller Form Personalnummer, akademischer Titel,
                Familienname, Vorname, Anschrift, Geburtsdatum, Geschlecht,
                Staatsbürgerschaft, Stammu. Zuteilungsdienststelle, Einreihung,
                Diensteintrittsdatum, Pensionierungsdatum,
                Bedienstetenkategorie, Gewerkschaftssektion, Hauptgruppe,
                Gewerkschaftsbeitrag [laufend und Durchrechnung] sowie
                Dienstende von meinem Arbeitgeber und der Gewerkschaft
                verarbeitet werden dürfen, wobei ich diese Einwilligung zum
                Betriebsabzug jederzeit gegenüber dem ÖGB widerrufen kann.
              </li>
            </ul>
          </div>
        </div>
        {/* Data protection */}
        <div className="signin-data-protection">
          <div style={{ width: "50%" }}>
            <h2>Datenschutzerklärung Mitgliederverwaltung</h2>Der Schutz Ihrer
            persönlichen Daten ist uns ein besonderes Anliegen. In dieser
            Datenschutzinformation informieren wir Sie über die wichtigsten
            Aspekte der Datenverarbeitung im Rahmen der Mitgliederverwaltung.
            Eine umfassende Information, wie der ÖGB mit Ihren personenbezogenen
            Daten umgeht, finden Sie unter{" "}
            <a href="http://www.oegb.at/datenschutz">www.oegb.at/datenschutz</a>{" "}
            <br />
            Verantwortlicher für die Verarbeitung Ihrer Daten ist der ÖGB. Wir
            verarbeiten die umseits von Ihnen angegebenen Daten mit hoher
            Vertraulichkeit, nur für Zwecke der Mitgliederverwaltung der
            Gewerkschaft und für die Dauer Ihrer Mitgliedschaft bzw. solange
            noch Ansprüche aus der Mitgliedschaft bestehen können. Rechtliche
            Basis der Datenverarbeitung ist Ihre Mitgliedschaft im ÖGB; soweit
            Sie dem Betriebsabzug zugestimmt haben, Ihre Einwilligung zur
            Verarbeitung der dafür zusätzlich erforderlichen Daten. <br /> Die
            Datenverarbeitung erfolgt durch den ÖGB selbst oder durch von diesem
            vertraglich beauftragte und kontrollierte Auftragsverarbeiter. Eine
            sonstige Weitergabe der Daten an Dritte erfolgt nicht oder nur mit
            Ihrer ausdrücklichen Zustimmung. Die Datenverarbeitung erfolgt
            ausschließlich im EU-Inland.
          </div>
          <div style={{ marginTop: "25px" }}>
            Ihnen stehen gegenüber dem ÖGB in Bezug auf die Verarbeitung Ihrer
            personenbezogenen Daten die Rechte auf Auskunft, Berichtigung,
            Löschung und Einschränkung der Verarbeitung zu. Gegen eine Ihrer
            Ansicht nach unzulässige Verarbeitung Ihrer Daten können Sie
            jederzeit eine Beschwerde an die österreichische Datenschutzbehörde
            (<a href="http://www.dsb.gv.at">www.dsb.gv.at</a>) als
            Aufsichtsstelle erheben.
            <br /> <br />
            Sie erreichen uns über folgende Kontaktdaten:
            <div className="signin-address">
              <div style={{ width: "200px", marginRight: "24px" }}>
                <Img fluid={logo.childImageSharp.fluid} />
              </div>
              <div style={{ width: "400px" }}>
                Wiener Straße 7, 7000 Eisenstadt
                <br />
                Tel.: +43 (0)2682/770/21
                <br />
                E-Mail:{" "}
                <a href="mailto:jana.ferstl@younion.at">
                  jana.ferstl@younion.at
                </a>
                <br />
              </div>
            </div>
            Unseren Datenschutzbeauftragten erreichen Sie unter{" "}
            <a href="mailto:datenschutzbeauftragter@oegb.at">
              datenschutzbeauftragter@oegb.at
            </a>
          </div>
        </div>
        {/* Advertiser */}
        <div className="signin-advertiser">
          <div style={{ width: "50%" }}>
            <h4>Angaben zur Werberin/zum Werber:</h4>
            <InputField
              fieldName="recruitertext:"
              fieldTitle="Beitrittsanlass"
            />
          </div>
          <div style={{ width: "50%", marginTop: "-4px" }}>
            <InputField
              fieldName="recruitername"
              fieldTitle="Vorname/Familienname"
            />
            <InputField
              fieldName="recruiternumber"
              fieldTitle="Mitgliedsnummer"
            />
          </div>
        </div>
        <button
          className="signin-submit"
          type="submit"
          disabled={Object.keys(submitText.success).length}
        >
          Anfrage Absenden
        </button>
        <div className="submit-text">
          {Object.keys(submitText.success).length
            ? submitText.success.text
            : null}
          {Object.keys(submitText.error).length
            ? Object.values(submitText.error).map(error => `${error} `)
            : null}
        </div>
        {/* <span style={{ marginLeft: "20px" }}>
          Sie können sich auch Anmelden via der PDF Datei{" "}
          <Link to="/Mitgliedsanmeldung.pdf">hier</Link>
        </span> */}
      </form>
    </div>
  )
}
