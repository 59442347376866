import React from "react"
import { graphql } from 'gatsby'

import SigninForm from "../components/form/SigninForm"

export default ({ data }) => (
  <div>
    <h1>Mitgliedsanmeldung </h1>
    <SigninForm logo={data.younionLogo} />
  </div>
)

export const query = graphql`
  query {
    younionLogo: file(relativePath: { eq: "younion_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
